import { createApp, createSSRApp, h } from 'vue'
import { createInertiaApp, router } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import { i18nVue } from 'laravel-vue-i18n';
import Vue3SocialSharingPlugin from 'vue3-social-sharing';

// Vue.use(VueSocialSharing);

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

import.meta.glob([
    '../images/**',
    '../fonts/**'
]);

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createSSRApp({ render: () => h(App, props) });
        return app
            .use(plugin)
            .use(ZiggyVue)
            .use(Vue3SocialSharingPlugin)
            .use(i18nVue, {
                lang: location.pathname.split('/')[1],
                resolve: (lang) => {
                    return props.initialPage.props.translations[lang];
                    // const langs = import.meta.glob('../../lang/*.json');
                    // return await langs[`../../lang/${lang}.json`]();
                },
                onLoad: () => { // Causes max call stack exceeded if loadAsyncLanguage is used.
                    if (el && el.__vue_app__) {
                        return;
                    }
                    app.mount(el); // Mounted here so translations are loaded before vue.
                }
            })
    },
    progress: {
        color: '#126E64',
    },
});

router.on("navigate", (event) => {
    if (typeof window !== 'undefined' && window.gtag) {
        window.gtag("js", new Date());
        window.gtag('config', 'G-Y8M23QFY8C');
    }
});
